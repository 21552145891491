





import { useRoute, defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
  name: "MaintenanceLayout",

  head: {},
});
