import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _115548ca = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _81ea5b5c = () => interopDefault(import('../modules/hudson/components/pages/AboutUs.vue' /* webpackChunkName: "" */))
const _e7593cda = () => interopDefault(import('../modules/hudson/components/pages/BrandsList.vue' /* webpackChunkName: "" */))
const _03f60311 = () => interopDefault(import('../modules/cart/pages/Cart.vue' /* webpackChunkName: "" */))
const _e503b35e = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _27a262c6 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _5ade54ca = () => interopDefault(import('../modules/hudson/components/checkout/HudPayment.vue' /* webpackChunkName: "" */))
const _4d358908 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _90e710f8 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _03e208e5 = () => interopDefault(import('../modules/hudson/components/pages/ContactUs.vue' /* webpackChunkName: "" */))
const _334aaf70 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _bdb2f244 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _a0b36474 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _51c00fda = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _34025bdc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _614cbb1e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _e4866bac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _0a594199 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _39e18bf0 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _27318dd6 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _68e3505d = () => interopDefault(import('../modules/hudson/components/pages/Faq.vue' /* webpackChunkName: "" */))
const _3679d335 = () => interopDefault(import('../modules/auth/login/Login.vue' /* webpackChunkName: "" */))
const _cd912c8e = () => interopDefault(import('../pages/maintenance-mode.vue' /* webpackChunkName: "pages/maintenance-mode" */))
const _478a714b = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _261b61ac = () => interopDefault(import('../modules/auth/password-forgotten/ForgottenPassword.vue' /* webpackChunkName: "" */))
const _2efcd35e = () => interopDefault(import('../modules/auth/password-reset/ForgottenPasswordReset.vue' /* webpackChunkName: "" */))
const _102f4b4a = () => interopDefault(import('../modules/hudson/components/checkout/paymentRedirect/PaymentRedirect.vue' /* webpackChunkName: "" */))
const _40a9dd47 = () => interopDefault(import('../modules/hudson/components/checkout/paymentRetry/HudPaymentRetry.vue' /* webpackChunkName: "" */))
const _a56db93e = () => interopDefault(import('../modules/hudson/components/pages/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _38ced541 = () => interopDefault(import('../modules/auth/register/Register.vue' /* webpackChunkName: "" */))
const _54286ae4 = () => interopDefault(import('../modules/hudson/components/pages/SizeChart.vue' /* webpackChunkName: "" */))
const _6db9c8a0 = () => interopDefault(import('../modules/hudson/components/pages/Sustainability.vue' /* webpackChunkName: "" */))
const _731ad4b2 = () => interopDefault(import('../modules/hudson/components/pages/TermsAndCondition.vue' /* webpackChunkName: "" */))
const _4345a94e = () => interopDefault(import('../modules/hudson/components/pages/TrackYourOrder.vue' /* webpackChunkName: "" */))
const _e6dd9938 = () => interopDefault(import('../modules/catalog/pages/default-category.vue' /* webpackChunkName: "" */))
const _1fecd5fe = () => interopDefault(import('../modules/hudson/components/orderSummary/OrderSummary.vue' /* webpackChunkName: "" */))
const _0aedbb05 = () => interopDefault(import('../modules/catalog/pages/product.vue' /* webpackChunkName: "" */))
const _038a6fa6 = () => interopDefault(import('../modules/catalog/pages/brands.vue' /* webpackChunkName: "" */))
const _3258d558 = () => interopDefault(import('../modules/catalog/pages/category.vue' /* webpackChunkName: "" */))
const _13831ec0 = () => interopDefault(import('../modules/search/pages/searchPage.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/mt",
    component: _115548ca,
    name: "home___mt"
  }, {
    path: "/mt/about-us",
    component: _81ea5b5c,
    name: "about-us___mt"
  }, {
    path: "/mt/brands.html",
    component: _e7593cda,
    name: "brands___mt"
  }, {
    path: "/mt/cart",
    component: _03f60311,
    name: "cart___mt"
  }, {
    path: "/mt/checkout",
    component: _e503b35e,
    name: "checkout___mt",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___mt"
    }, {
      path: "payment",
      component: _5ade54ca,
      name: "payment___mt"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___mt"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___mt"
    }]
  }, {
    path: "/mt/contact-us",
    component: _03e208e5,
    name: "contact-us___mt"
  }, {
    path: "/mt/customer",
    component: _334aaf70,
    meta: {"titleLabel":"My Account"},
    name: "customer___mt",
    children: [{
      path: "addresses-details",
      component: _bdb2f244,
      meta: {"titleLabel":"My Addresses"},
      name: "customer-addresses-details___mt"
    }, {
      path: "my-newsletter",
      component: _a0b36474,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___mt"
    }, {
      path: "my-profile",
      component: _51c00fda,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___mt"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___mt"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___mt"
    }, {
      path: "addresses-details/create",
      component: _e4866bac,
      meta: {"titleLabel":"My Addresses"},
      name: "customer-addresses-details-new___mt"
    }, {
      path: "/mt/reset-password",
      component: _0a594199,
      name: "reset-password___mt"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _39e18bf0,
      meta: {"titleLabel":"My Addresses"},
      props: true,
      name: "customer-addresses-details-edit___mt"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___mt"
    }]
  }, {
    path: "/mt/faqs",
    component: _68e3505d,
    name: "faqs___mt"
  }, {
    path: "/mt/Home",
    component: _115548ca,
    name: "Home___mt"
  }, {
    path: "/mt/login",
    component: _3679d335,
    name: "login___mt"
  }, {
    path: "/mt/maintenance-mode",
    component: _cd912c8e,
    name: "maintenance-mode___mt"
  }, {
    path: "/mt/Page",
    component: _478a714b,
    name: "Page___mt"
  }, {
    path: "/mt/password-forgotten",
    component: _261b61ac,
    name: "password-forgotten___mt"
  }, {
    path: "/mt/password-reset",
    component: _2efcd35e,
    name: "password-reset___mt"
  }, {
    path: "/mt/payment-redirect",
    component: _102f4b4a,
    name: "payment-redirect___mt"
  }, {
    path: "/mt/payment-retry",
    component: _40a9dd47,
    props: true,
    name: "payment-retry___mt"
  }, {
    path: "/mt/privacy-policy",
    component: _a56db93e,
    name: "privacy-policy___mt"
  }, {
    path: "/mt/register",
    component: _38ced541,
    name: "register___mt"
  }, {
    path: "/mt/size-chart.html",
    component: _54286ae4,
    name: "size-chart___mt"
  }, {
    path: "/mt/sustainability",
    component: _6db9c8a0,
    name: "sustainability___mt"
  }, {
    path: "/mt/terms-and-conditions",
    component: _731ad4b2,
    name: "terms-and-conditions___mt"
  }, {
    path: "/mt/track-your-order.html",
    component: _4345a94e,
    name: "track-your-order___mt"
  }, {
    path: "/mt/c/all-products.html",
    component: _e6dd9938,
    name: "default-category___mt"
  }, {
    path: "/mt/order-summary/:orderId",
    component: _1fecd5fe,
    props: true,
    name: "order-summary___mt"
  }, {
    path: "/mt/p/:id/:slug",
    component: _0aedbb05,
    name: "product___mt"
  }, {
    path: "/mt/b/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _038a6fa6,
    name: "brand___mt"
  }, {
    path: "/mt/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _3258d558,
    name: "category___mt"
  }, {
    path: "/mt/s/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _13831ec0,
    name: "searchPage___mt"
  }, {
    path: "/mt/:slug+",
    component: _478a714b,
    name: "page___mt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
