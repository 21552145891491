import { Logger } from '~/helpers/logger';
import { useContext } from '@nuxtjs/composition-api';
import type { Product } from "~/modules/catalog/product/types";
import type {
    ProductInterface
} from "~/modules/GraphQL/types";
import type { Product as WishlistProduct } from "~/modules/wishlist/composables/useWishlist";
import { UseGtmEventsInterface } from './useGtmEvents';



export function useGtmEvents(): UseGtmEventsInterface {
    const context = useContext();
    const locationId = context.env.GTM_STORE_LOCATION_ID;


    //--------Common Event data parser ---------------
    const gtmProductDetailsParser = (iData, iIndex, iQty = 0) => {
        let parsedObj = {};
        if (iData) {
            parsedObj = {
                item_id: iData?.sku,
                item_name: iData?.name,
                index: iIndex,
                item_variant: '',
                item_category: iData?.categories && iData?.categories.length ? iData?.categories[0]?.name : '',
                item_category1: iData?.categories && iData?.categories.length > 1 ? iData?.categories[1]?.name : '',
                item_category2: iData?.categories && iData?.categories.length > 2 ? iData?.categories[2]?.name : '',
                location_id: locationId,
                currency:
                    iData?.price_range?.minimum_price?.final_price.currency ||
                    iData?.price_range?.minimum_price?.regular_price.currency ||
                    iData?.price_range?.maximum_price?.final_price.currency ||
                    iData?.price_range?.maximum_price?.regular_price.currency || 'EUR',
                price:
                    iData?.price_range?.minimum_price?.final_price.value ||
                    iData?.price_range?.minimum_price?.regular_price.value ||
                    iData?.price_range?.maximum_price?.final_price.value ||
                    iData?.price_range?.maximum_price?.regular_price.value || '',
                discount:
                    (iData?.price_range?.minimum_price?.regular_price?.value - iData?.price_range?.minimum_price?.final_price?.value) ||
                    (iData?.price_range?.maximum_price?.regular_price?.value - iData?.price_range?.maximum_price?.final_price?.value) || '0',
                quantity: iQty || iData?.only_x_left_in_stock || (iData?.stock_status === "IN_STOCK" ? 1 : 0),
            }
        }

        return parsedObj
    }


    //-------------------Events------------------------

    //---------View Cart--------------
    const gtmViewCart = async (): Promise<void> => {
        try {
            let cartInfo = context.$pinia?.state?.value?.cart?.cart;
            if (cartInfo) {
                let products = cartInfo?.items;
                const itemsInfo = [];

                products.forEach((item, index) => {
                    let product = item?.product;
                    let userSelectedQty = item?.quantity
                    itemsInfo.push(gtmProductDetailsParser(product, index, userSelectedQty))
                });
                context.app.$gtm.push({
                    event: 'view_cart',
                    ecommerce: {
                        items: itemsInfo
                    }
                });
            }
        }
        catch (err) {
            Logger.error(`useGtmEvent`, err);
        }
    };

    //------Add Product in Cart-------------
    const gtmAddToCart = async (iProduct: Product): Promise<void> => {
        try {
            const itemsInfo = []
            itemsInfo.push(gtmProductDetailsParser(iProduct, 0, 1));
            context.app.$gtm.push({ event: 'add_to_cart', ecommerce: { items: itemsInfo } });
        }
        catch (err) {
            Logger.error(`useGtmEvent`, err);
        }

    }

    //------Remove product from Cart-------------
    const gtmRemoveFromCart = async (iProduct: ProductInterface): Promise<void> => {
        try {
            const itemsInfo = []
            itemsInfo.push(gtmProductDetailsParser(iProduct, 0, 1));
            context.app.$gtm.push({ event: 'remove_from_cart', ecommerce: { items: itemsInfo } });
        }
        catch (err) {
            Logger.error(`useGtmEvent`, err);
        }
    }

    //------Add Product in Wishlist-------------
    const gtmAddToWishlist = async (iProduct: WishlistProduct): Promise<void> => {
        try {
            const itemsInfo = []
            itemsInfo.push(gtmProductDetailsParser(iProduct, 0, 1));
            context.app.$gtm.push({ event: 'add_to_wishlist', ecommerce: { items: itemsInfo } });
        }
        catch (err) {
            Logger.error(`useGtmEvent`, err);
        }
    }

    //------Remove Product in Wishlist-------------
    const gtmRemoveFromWishlist = async (iProduct: WishlistProduct): Promise<void> => {
        try {
            const itemsInfo = []
            itemsInfo.push(gtmProductDetailsParser(iProduct, 0, 1));
            context.app.$gtm.push({ event: 'remove_from_wishlist', ecommerce: { items: itemsInfo } });
        }
        catch (err) {
            Logger.error(`useGtmEvent`, err);
        }
    }

    //------View Item-------------
    const gtmViewItem = async (iProduct: Product): Promise<void> => {
        try {
            const itemsInfo = []
            itemsInfo.push(gtmProductDetailsParser(iProduct, 0, 1));
            context.app.$gtm.push({ event: 'view_item', ecommerce: { items: itemsInfo } });
        }
        catch (err) {
            Logger.error(`useGtmEvent`, err);
        }
    }

    //------View Item List-------------
    const gtmViewItemList = async (iProducts: ProductInterface[]): Promise<void> => {
        try {
            const itemsInfo = [];
            iProducts.forEach((product, index) => {
                itemsInfo.push(gtmProductDetailsParser(product, index))
            });

            context.app.$gtm.push({ event: 'view_item_list', ecommerce: { items: itemsInfo } });
        }
        catch (err) {
            Logger.error(`useGtmEvent`, err);
        }
    }

    //------Begin Checkout  -------------
    const gtmBeginCheckout = async (): Promise<void> => {
        try {
            let cartInfo = context.$pinia?.state?.value?.cart?.cart;
            if (cartInfo) {
                let products = cartInfo?.items;
                const itemsInfo = [];
                products.forEach((item, index) => {
                    let product = item?.product;
                    let userSelectedQty = item?.quantity;
                    itemsInfo.push(gtmProductDetailsParser(product, index, userSelectedQty))
                });
                context.app.$gtm.push({
                    event: 'begin_checkout',
                    ecommerce: {
                        items: itemsInfo
                    }
                });
            }
        }
        catch (err) {
            Logger.error(`useGtmEvent`, err);
        }
    }

    //------Add Shipping Info -------------
    const gtmAddShippingInfo = async (): Promise<void> => {
        try {
            let cartInfo = context.$pinia?.state?.value?.cart?.cart;

            if (cartInfo) {
                let currency = context.$pinia?.state?.value?.magentoConfig?.storeConfig?.default_display_currency_code || "EUR";
                let cartValue = cartInfo?.prices?.grand_total?.value
                let couponValue = cartInfo?.applied_coupons ? cartInfo?.applied_coupons[0]?.code : ""

                let products = cartInfo?.items;
                const itemsInfo = [];
                products.forEach((item, index) => {
                    let product = item?.product;
                    let userSelectedQty = item?.quantity;
                    itemsInfo.push(gtmProductDetailsParser(product, index, userSelectedQty))
                });
                context.app.$gtm.push({
                    event: 'add_shipping_info',
                    ecommerce: {
                        value: cartValue,
                        currency,
                        coupon: couponValue,
                        items: itemsInfo
                    }
                });
            }
        }
        catch (err) {
            Logger.error(`useGtmEvent`, err);
        }
    }

    //------Purchase -------------
    const gtmPurchase = async (): Promise<void> => {
        try {
            let cartInfo = context.$pinia?.state?.value?.cart?.cart;
            if (cartInfo) {
                let currency = context.$pinia?.state?.value?.magentoConfig?.storeConfig?.default_display_currency_code || "EUR";
                let cartValue = cartInfo?.prices?.grand_total?.value
                let couponValue = cartInfo?.applied_coupons ? cartInfo?.applied_coupons[0]?.code : ""
                let products = cartInfo?.items;
                const itemsInfo = [];
                products.forEach((item, index) => {
                    let product = item?.product;
                    let userSelectedQty = item?.quantity;
                    itemsInfo.push(gtmProductDetailsParser(product, index, userSelectedQty))
                });
                context.app.$gtm.push({
                    event: 'purchase',
                    ecommerce: {
                        value: cartValue,
                        currency,
                        coupon: couponValue,
                        items: itemsInfo
                    }
                });
            }
        }
        catch (err) {
            Logger.error(`useGtmEvent`, err);
        }
    }

    return {
        gtmViewCart,
        gtmAddToCart,
        gtmRemoveFromCart,
        gtmAddToWishlist,
        gtmRemoveFromWishlist,
        gtmViewItem,
        gtmViewItemList,
        gtmBeginCheckout,
        gtmAddShippingInfo,
        gtmPurchase
    };
}

export default useGtmEvents;