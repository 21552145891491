const middleware = {}

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['maintenance-mode'] = require('../middleware/maintenance-mode.js')
middleware['maintenance-mode'] = middleware['maintenance-mode'].default || middleware['maintenance-mode']

export default middleware
