export default `
mutation setPaymentMethodAndPlaceOrder(
    $cartId: String!
 ) {
    setPaymentMethodOnCart(
        input: {
            cart_id: $cartId
            payment_method: {
                code: "adyen_cc"
           }
        }
    ) {
        cart {
            selected_payment_method {
                code
                title
            }
        }
    }
 
    placeOrder(
        input: {
            cart_id: $cartId
        }
    ) {
        order {
            order_number
            cart_id
           
        }
    }
 }
`;
