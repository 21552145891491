import B from 'bowser'

export default ({ app: { head, context } }, inject) => {
  let _userAgent = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 11_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.77 Safari/537.36'

  if (typeof context.req !== 'undefined') {
    _userAgent = context.req.headers['user-agent']
  } else if (typeof navigator !== 'undefined') {
    _userAgent = navigator.userAgent
  }

  const browser = B.getParser(_userAgent)

  inject('browser', browser)
}
