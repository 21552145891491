






































































































import {
  SfOverlay,
  SfHeader,
  SfButton,
  SfBadge,
  SfImage,
} from "@storefront-ui/vue";

import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  onUnmounted,
  useFetch,
} from "@nuxtjs/composition-api";
import HudsonHeaderNavigation from "~/modules/header/HudsonNavigation/HnHeaderNavigation.vue";
import HeaderNavigation from "~/components/Header/Navigation/HeaderNavigation.vue";
import { useCategory } from "~/modules/catalog/category/composables/useCategory";
import { useUiHelpers, useUiState } from "~/composables";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlistStore } from "~/modules/wishlist/store/wishlistStore";
import type { CategoryTree, ProductInterface } from "~/modules/GraphQL/types";
import SvgImage from "~/components/General/SvgImage.vue";
import { useTopBar } from "./TopBar/useTopBar";
import { useCategoryStore } from "~/modules/catalog/category/stores/category";
import { addBasePath } from "~/helpers/addBasePath";

const HnMobileCategorySidebar = () =>
  import(
    "~/modules/header/HnMobileCategorySidebar/HnMobileCategorySidebar.vue"
  );

export default defineComponent({
  components: {
    HudsonHeaderNavigation,
    HeaderNavigation,
    HnMobileCategorySidebar,
    SfHeader,
    SfOverlay,
    SvgImage,
    SfImage,
    SfButton,
    SfBadge,
    CurrencySelector: () => import("~/components/CurrencySelector.vue"),
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
    HudsonSearchBar: () =>
      import("~/modules/header/HudsonSearchBar/HudsonSearchBar.vue"),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"
      ),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const {
      isMobileMenuOpen,
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleMobileMenu,
      toggleSearchPopup,
      isCartSidebarOpen,
    } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount, load: loadWishlist } =
      useWishlist();
    const { categories: categoryList, load: categoriesListLoad } =
      useCategory();

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const productSearchResults = ref<ProductInterface[] | null>(null);
    const isStickyHeader = ref(false);
    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() =>
      isAuthenticated.value ? "profile_fill" : "profile"
    );
    const categoryTree = ref<CategoryTree[]>([]);

    const handleAccountClick = async () => {
      isAuthenticated.value
        ? await router.push(app.localeRoute({ name: "customer-my-profile" }))
        : await router.push(app.localeRoute({ name: "login" }));
    };
    const handleSearchClick = async () => {
      toggleSearchPopup();
    };
    const scrollListener = (e) => {
      isStickyHeader.value = window.scrollY > 40;
    };

    const loadCategoryMenu = async () => {
      const categories = useCategoryStore();

      if (categories.categories === null) {
        await categories.load();
      }
      toggleMobileMenu();
    };

    useFetch(async () => {
      // await categoriesListLoad({ pageSize: 20 });
      // categoryTree.value = categoryList.value?.[0]?.children.filter(
      //   (category) => category.include_in_menu
      // );

      const categoryStore = useCategoryStore();
      if (categoryStore.categories === null) {
        await categoryStore.load();
      }
      categoryTree.value = categoryStore.categories?.children?.filter(
        (category) => category.include_in_menu
      );
      categoryTree.value.push({
        is_anchor: 1,
        name: "Brands",
        position: categoryTree.value.length,
        uid: "",
        url_path: "brands",
        url_suffix: ".html",
        include_in_menu: categoryTree.value.length,
        image: null,
        thumbnail_image: null,
        children: [],
        redirect_code: 3,
      });
    });

    onMounted(async () => {
      window.addEventListener("scroll", scrollListener);
      await loadCartTotalQty();

      if (app.$device.isDesktop) {
        // eslint-disable-next-line promise/catch-or-return
        // await loadWishlistItemsCount();
        await loadWishlist();
      }
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", scrollListener);
    });

    return {
      accountIcon,
      addBasePath,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      handleSearchClick,
      isAuthenticated,
      isMobileMenuOpen,
      loadCategoryMenu,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      isStickyHeader,
      isCartSidebarOpen,
    };
  },
});
