
























import {
  defineComponent,
  useContext,
  useRouter,
} from "@nuxtjs/composition-api";
import type { PropType } from "@nuxtjs/composition-api";
import { SfButton, SfImage } from "@storefront-ui/vue";
import type { NuxtError } from "@nuxt/types";
import { addBasePath } from "~/helpers/addBasePath";

export default defineComponent({
  components: {
    SfButton,
    SfImage,
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const handleRedirect = async () => {
      // await router.push(app.localeRoute({ name: "/Home" }));
      // used window.location because router.push was not redirecting to home page need to optimize it.
      window.location.replace("/");
    };
    return {
      addBasePath,
      handleRedirect,
    };
  },
});
