
export default `
query (
    $cartId: String!
 ) {
  adyenPaymentMethods(cart_id: $cartId) {
        paymentMethodsResponse {
            paymentMethods {
                name
                type
                brand
                brands
            }
        }
  }
   cart(cart_id: $cartId) {
     available_payment_methods {
      code
      title
    }
  }
}
`;
