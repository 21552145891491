/** GraphQL Query that fetches store configuration from the API */
export const StoreConfigQuery = `
  query storeConfig {
    storeConfig {
        list_mode,
        store_code,
        default_title,
        store_name,
        default_display_currency_code,
        locale,
        header_logo_src,
        logo_width,
        logo_height,
        logo_alt,
        copyright,
        list_per_page,
        list_per_page_values,
        grid_per_page,
        grid_per_page_values,
        general_country_allow_for_shipping,
        free_shipping_threshold_limit_for_banner
    }
  }
`;

export default StoreConfigQuery;
