import { computed, reactive } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';
import { perPageOptions } from '~/modules/catalog/category/composables/useFacet/perPageOptions';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  itemsPerPageValue: null,
  isSearchPopupOpen: false,
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const { config } = useConfig();

  const toggleSearchPopup = () => {
    state.isSearchPopupOpen = !state.isSearchPopupOpen;
  };

  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    let intervalId = setInterval(() => {
      if (window.pageYOffset === 0) {
        clearInterval(intervalId);
      }
      window.scroll(0, window.pageYOffset - 50);
    }, 0);
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  /**
   * Change the view type to be grid or not as per the param.
   * 
   * @param {boolean} value
   */
  const changeToCategoryGridView = (value: boolean): void => {
    state.isCategoryGridView = value;
  }

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };

  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const changeItemsPerPageValue = (value: number) => {
    state.itemsPerPageValue = value;
  };

  const pageOptions = () => {
    if (config.value.grid_per_page_values && config.value.list_per_page_values) {
      return state.isCategoryGridView ? config.value.grid_per_page_values.split(",").map(i => +i) : config.value.list_per_page_values.split(",").map(i => +i)
    }

    return perPageOptions
  }

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    itemsPerPageValue: computed(() => state.itemsPerPageValue || (state.isCategoryGridView ? config.value.grid_per_page : config.value.list_per_page)),
    pageOptions: computed(pageOptions),
    isSearchPopupOpen: computed(() => state.isSearchPopupOpen),
    toggleMobileMenu,
    changeItemsPerPageValue,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleSearchPopup
  };
}

export default useUiState;
export * from './useUiState';
