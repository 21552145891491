













import { SfIcon } from "@storefront-ui/vue";
import {
  ref,
  defineComponent,
  onMounted,
  onUnmounted,
} from "@nuxtjs/composition-api";

export default defineComponent({
  name: "HnScrollToTop",
  components: { SfIcon },
  setup() {
    const isVisible = ref(false);

    const scrollTop = () => {
      let intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 0);
    };

    const scrollListener = (e) => {
      isVisible.value = window.scrollY > 350;
    };

    onMounted(() => {
      window.addEventListener("scroll", scrollListener);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", scrollListener);
    });

    return {
      isVisible,
      scrollTop,
      scrollListener,
    };
  },
});
