

























import { SfImage, SfLink } from "@storefront-ui/vue";
import { computed, defineComponent } from "@nuxtjs/composition-api";
import { useConfig } from "~/composables";
import SvgImage from "~/components/General/SvgImage.vue";

export default defineComponent({
  name: "HeaderLogo",
  components: { SvgImage, SfImage, SfLink },
  setup() {
    const { config } = useConfig();

    const logoSrc = computed(() => {
      const baseMediaUrl = process.env.VsfImageProviderBaseUrl;
      const logo = config.value.header_logo_src;

      return baseMediaUrl && logo ? `${baseMediaUrl}/logo/${logo}` : "";
    });
    const logoWidth = computed(() => config?.value?.logo_width || "329");

    const logoHeight = computed(() => config?.value?.logo_height || "44");

    const logoAlt = computed(() => config?.value?.logo_alt || "");

    return {
      logoAlt,
      logoHeight,
      logoSrc,
      logoWidth,
    };
  },
});
