// TODO hudson specific file

import axios from "axios";

export default function ({ $axios, app }, inject) {

  let config = {
    baseURL: 'https://api.clerk.io/v2/',
    timeout: 15000,
    headers: { accept: 'application/json' }
  };
  // // Create a custom axios instance
  const clerkIoApi = axios.create(config)

  inject('clerkIoApi', clerkIoApi)
}
