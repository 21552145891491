




























import { SfLink, SfIcon, SfImage } from "@storefront-ui/vue";
import { defineComponent } from "@nuxtjs/composition-api";
import { addBasePath } from "~/helpers/addBasePath";

export default defineComponent({
  components: {
    SfLink,
    SfIcon,
    SfImage,
  },
  setup() {
    return {
      addBasePath,
    };
  },
});
