// import gql from 'graphql-tag';

export default `
query categoryList {
	categories {
		items {
			product_count
			name
			uid
		}
	}
}
`;