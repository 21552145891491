import type { UseNewsletterUpdateSubscriptionParams } from '~/composables';
import type { SubscribeEmailToNewsletterResponse } from '~/modules/GraphQL/types';
import { UseContextReturn } from '~/types/core';

export const updateSubscriptionCommand = {
  execute: async (context: UseContextReturn, params: UseNewsletterUpdateSubscriptionParams): Promise<SubscribeEmailToNewsletterResponse> => {
    const { data, errors } = await context.app.$vsf.$magento.api.subscribeEmailToNewsletter({
      email: params.email,
    }, params?.customQuery ?? null);

    return { data, errors };
  },
};
