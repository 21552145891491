





















import { defineComponent } from "@nuxtjs/composition-api";
import { SfButton, SfTopBar, SfIcon, SfLink } from "@storefront-ui/vue";
import { useTopBar } from "./useTopBar";
import ContactInfo from "~/modules/hudson/components/topbar/ContactInfo.vue";

export default defineComponent({
  components: {
    CurrencySelector: () => import("../CurrencySelector.vue"),
    StoreSwitcher: () => import("../StoreSwitcher.vue"),
    SfTopBar,
    SfButton,
    SfIcon,
    SfLink,
    ContactInfo,
  },
  setup() {
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    return {
      hasCurrencyToSelect,
      hasStoresToSelect,
    };
  },
});
