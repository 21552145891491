// TODO hudson specific file
import axios from "axios";

export default function (nuxtContext, inject) {

  let config = {
    baseURL: nuxtContext.$config.middlewareUrl,
    timeout: 15000,
    headers: {
      'X-Custom-Header': 'foobar',
    }
  };
  // // Create a custom axios instance
  const hudapi = axios.create(config);

  // Inject to context as $api
  inject('hudapi', hudapi)
}
