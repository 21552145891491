export default `
mutation setPaymentMethodAndPlaceOrder(
    $cartId: String!
    $stateData: String!
    $payType: String!
 ) {
    setPaymentMethodOnCart(
        input: {
            cart_id: $cartId
            payment_method: {
                code: "adyen_hpp"
                adyen_additional_data_hpp: {
                    brand_code: $payType
                    stateData: $stateData
                }
            }  
        }
    ) {
        cart {
            selected_payment_method {
                code
                title
            }
        }
    }
 
    placeOrder(
        input: {
            cart_id: $cartId
        }
    ) {
        order {
            order_number
            cart_id
        }
    }
 }
`;
