var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[{ 'hn-sticky-head': _vm.isStickyHeader }]},[_c('SfHeader',{scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('HudsonSearchBar'),_vm._v(" "),_c('SfButton',{staticClass:"sf-button--pure hre-header-menu",attrs:{"aria-label":"Menu","data-testid":"mobile-navigation-menu"},on:{"click":_vm.loadCategoryMenu}},[_c('SfImage',{attrs:{"src":_vm.addBasePath('/hudson/menu.svg'),"alt":"$t('Menu')","width":"18","height":"18"}})],1)]},proxy:true},{key:"navigation",fn:function(){return [_c('HudsonHeaderNavigation',{attrs:{"category-tree":_vm.categoryTree}})]},proxy:true},{key:"aside",fn:function(){return [_c('div',{staticClass:"sf-header__switchers"},[(_vm.hasStoresToSelect)?_c('StoreSwitcher',{staticClass:"smartphone-only"}):_vm._e()],1)]},proxy:true},{key:"header-icons",fn:function(ref){
var activeIcon = ref.activeIcon;
return [_c('div',{staticClass:"sf-header__icons"},[_c('SfButton',{staticClass:"sf-button--pure sf-header__action",class:{
            isAuthenticated: _vm.isAuthenticated,
          },attrs:{"data-e2e":"app-header-account","data-testid":"accountIcon","aria-label":"Account"},on:{"click":_vm.handleAccountClick}},[_c('SfImage',{attrs:{"src":_vm.addBasePath('/hudson/account.svg'),"alt":"$t('Account')","width":"18","height":"18"}})],1),_vm._v(" "),(_vm.isAuthenticated)?_c('SfButton',{staticClass:"sf-button--pure sf-header__action",attrs:{"data-testid":"wishlistIcon","aria-label":"Wishlist"},on:{"click":_vm.toggleWishlistSidebar}},[_c('SvgImage',{staticClass:"sf-header__icon",class:{
              'sf-header__icon is-active': activeIcon === 'wishlist',
            },attrs:{"icon":_vm.wishlistHasProducts ? 'heart_fill' : 'heart',"label":_vm.$t('Wishlist'),"width":"24","height":"24"}}),_vm._v(" "),(_vm.wishlistHasProducts)?_c('SfBadge',{staticClass:"sf-badge--number cart-badge"},[_vm._v("\n            "+_vm._s(_vm.wishlistItemsQty)+"\n          ")]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-cart'),expression:"'app-header-cart'"}],staticClass:"sf-button--pure sf-header__action",attrs:{"aria-label":"Toggle cart sidebar"},on:{"click":_vm.toggleCartSidebar}},[_c('SfImage',{staticClass:"sf-header__icon",class:{
              'sf-header__icon is-active': activeIcon === 'cart',
            },attrs:{"src":_vm.addBasePath('/hudson/cart.svg'),"alt":"$t('Cart')","width":"22","height":"18"}}),_vm._v(" "),(_vm.cartTotalItems)?_c('SfBadge',{staticClass:"sf-badge--number cart-badge"},[_vm._v("\n            "+_vm._s(_vm.cartTotalItems)+"\n          ")]):_vm._e()],1)],1)]}}])}),_vm._v(" "),(_vm.isMobileMenuOpen)?_c('HnMobileCategorySidebar'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }