import { Plugin } from '@nuxt/types';
import { ref, set } from '@nuxtjs/composition-api';
import { nanoid } from 'nanoid';
import { PiniaPluginContext } from 'pinia';
import type { StoreConfig } from '~/modules/GraphQL/types';
import StoreConfigGql from '~/plugins/query/StoreConfig.gql';

const storeConfigPlugin: Plugin = async ({ $pinia, app, $cookies }) => {
  let isMaintenance = !!app.context.vsfMaintenanceMode;
  if (!isMaintenance) {
    const { data }: { data: { storeConfig?: StoreConfig } } = await app.$vsf.$magento.api.customQuery({ query: StoreConfigGql });

    const cookieRes = $cookies.get('clerk-tracking-id')
    if (!cookieRes) {
      $cookies.set('clerk-tracking-id', nanoid(8), {
        path: '/',
        maxAge: 60 * 60 * 24 * 7
      })
    }

    $pinia.use(({ store }: PiniaPluginContext) => {
      if (store.$id !== 'magentoConfig') return;
      const storeConfig = ref(data?.storeConfig ?? {});

      // eslint-disable-next-line no-prototype-builtins
      if (!store.$state.hasOwnProperty('storeConfig')) {
        set(store.$state, 'storeConfig', storeConfig);
      } else {
        // eslint-disable-next-line no-param-reassign
        store.$state.storeConfig = storeConfig;
      }
    });
  }

};

export default storeConfigPlugin;
