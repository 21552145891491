// TODO hudson specific file
import { readonly, ref, useContext, computed } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import useCart from '~/modules/checkout/composables/useCart';
import { useApi } from "~/composables/useApi";
import getAllPaymentMethodsGQL from "../../customQueries/getAllPaymentMethods.gql"
import ccPlaceOrderGQL from "../../customQueries/placeOrder/paymentTypeCC.gql"
import hppPlaceOrderGQL from "../../customQueries/placeOrder/paymentTypeHpp.gql"

import type {
  usePaymentMethodsErrors,
  paymentMethodParams
} from './usePaymentMethods';

/**
 * Allows loading the available payment
 * methods for current cart, and selecting (saving) one of them.
 *
 * See the {@link usePaymentMethodsInterface} for a list of methods and values available in this composable.
 */
export function usePaymentMethods(): any {
  const context = useContext();
  const loading = ref(false);
  const { mutate, query } = useApi();
  const error = ref<usePaymentMethodsErrors>({
    load: null,
    save: null
  });
  const { cart } = useCart();


  const loadPaymentMethods = async () => {
    Logger.debug('usePaymentMethods.load');
    let result = null;
    try {
      loading.value = true;
      const isApplePlatForm = context.app.$browser.is('macos') || context.app.$browser.is('ios');
      const isAppleBrowser = context.app.$browser.is('safari');
      // Change "yourIntegration" to the name of the integration
      result = (await context.app.$hudapi.post('/adyen/getPaymentMethods', {
        isApplePlatForm,
        isAppleBrowser
      })).data;
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error(`usePaymentMethods/load`, err);
    } finally {
      loading.value = false;
    }

    Logger.debug('usePaymentMethods [Result]:', { result });
    return result;
  };

  const getAllPaymentMethods = async (iCartId: String) => {
    Logger.debug('usePaymentMethods.getAllPaymentMethods');
    let result = null;
    try {
      loading.value = true;
      //----as of 10-sep-2022--- dont show apple pay
      const isApplePlatForm = false; //context.app.$browser.is('macos') || context.app.$browser.is('ios');
      const isAppleBrowser = false; //context.app.$browser.is('safari');

      const { data, errors } = <any>await query(getAllPaymentMethodsGQL, {
        cartId: iCartId,
      });


      let paymentMethods = [];
      let ifFreePayment = false;

      //Process Magento payment methods
      let magentoPayMethods = data?.cart?.available_payment_methods;
      if (magentoPayMethods && magentoPayMethods.length > 0) {

        //check if no-payment-required method found
        let freePay = magentoPayMethods.find(p => p.code === "free");
        if (freePay) {
          let payMethodObj = {
            name: freePay.title,
            type: freePay.code,
            code: freePay.code,
            origin: "magento"
          }
          paymentMethods.push(payMethodObj);
          ifFreePayment = true;
        }

        //if no-payment-required found then no need to load other methods
        if (!ifFreePayment) {
          magentoPayMethods.forEach((p) => {
            let payMethodObj = null;
            if (p.code !== 'adyen_cc' && p.code !== 'adyen_hpp') {
              payMethodObj = {
                name: p.title,
                type: p.code,
                code: p.code,
                origin: "magento"
              }
              paymentMethods.push(payMethodObj);
            }

          });
        }
      }

      //Process Adyen payment methods [if no-payment-required found then no need to load adyen methods]
      if (!ifFreePayment) {
        let adyenPayMethods = data?.adyenPaymentMethods?.paymentMethodsResponse?.paymentMethods
        if (adyenPayMethods && adyenPayMethods.length > 0) {
          adyenPayMethods.forEach((p) => {
            let code = p.type;
            if (p.type === 'scheme') {
              code = 'adyen_cc'
            }
            if (isApplePlatForm && isAppleBrowser || p.type !== 'applepay') {
              paymentMethods.push({ ...p, code, origin: "adyen" });
            }
          });
        }
      }

      result = paymentMethods;
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error(`usePaymentMethods/getAllPaymentMethods`, err);
    } finally {
      loading.value = false;
    }
    Logger.debug('usePaymentMethods.getAllPaymentMethods [Result]:', { result });
    return result;
  };

  const savePaymentMethod = async (params) => {
    Logger.debug('usePaymentProvider.save');
    let result = null;

    try {
      loading.value = true;
      const paymentMethodParams: paymentMethodParams = {
        cart_id: cart.value.id,
        payment_method: {
          ...params.paymentMethod,
        },
      };

      const { data } = await context.app.$vsf.$magento.api.setPaymentMethodOnCart(paymentMethodParams);
      result = data?.setPaymentMethodOnCart?.cart.available_payment_methods ?? [];

      error.value.save = null;
    } catch (err) {
      error.value.save = err;
      Logger.error('usePaymentProvider/save', err);
    } finally {
      loading.value = false;
    }

    Logger.debug('[Result]:', { result });
    return result;
  };

  //----------Save payment method and place order together------------------
  const savePaymentMethodAndPlaceOrder = async (params) => {
    let result = null;
    let orderId = null;
    Logger.debug('usePaymentProvider.savePaymentMethodAndPlaceOrder');
    try {
      loading.value = true;
      if (params.payProvider === "scheme") {
        const { data, errors } = await mutate(
          ccPlaceOrderGQL,
          {
            cartId: cart.value.id,
          }
        );
        if (data) {
          let res = <any>data;
          orderId = res?.placeOrder?.order?.order_number
        }
        result = { orderId, errors };

      } else {
        const { data, errors } = await mutate(
          hppPlaceOrderGQL,
          {
            cartId: cart.value.id,
            payType: params.payProvider,
            stateData: params.stateData
          }
        );

        if (data) {
          let res = <any>data;
          orderId = res?.placeOrder?.order?.order_number
        }
        result = { orderId, errors };
      }
    } catch (err) {
      Logger.error(`usePaymentProvider.savePaymentMethodAndPlaceOrder`, err);
    } finally {
      loading.value = false;
    }
    return result;
  };

  return {
    loadPaymentMethods,
    savePaymentMethod,
    error: readonly(error),
    loading: readonly(loading),
    getAllPaymentMethods,
    savePaymentMethodAndPlaceOrder
  };
}

export * from './usePaymentMethods';
export default usePaymentMethods;