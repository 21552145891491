export default function (args) {
  let isMaintenance = !!args.$config.vsfMaintenanceMode;
  if (isMaintenance) {
    return args.redirect('/mt/maintenance-mode')
  }
  if (isMaintenance === false && args.route.path?.split('/').includes('maintenance-mode')) {
    return args.redirect('/')
  }
  // next()
  // return args.redirect(args.route)
}